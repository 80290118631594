import Image from 'next/image';
import styles from './../../styles/Header.module.scss';
import Link from 'next/link';
import {useRouter} from 'next/router';
import strings from "utils/strings";
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {openDeposit, openSignupModal, openWheelModal, openLoginModal} from 'store/modals/actions';
import {
    showGuestSideBar,
    showTermsSideBar,
    openAccountSidebar,
} from 'store/common/actions';
import {showSearchBar} from "store/common/actions";
import React, {FC, useRef, useState, useEffect} from 'react';
import useAuth from "hooks/auth";
import UserDropDown from 'themes/spins/ui/header/userDropDown';
import Balance from 'themes/spins/ui/balance/balance-view';
import SearchBar from 'themes/spins/ui/search/bar';
import FavoriteFalseIcon from '@/template/svg/favoriteFalse';
import SearchIcon from 'themes/spins/svg/SearchIcon';
import InfoIcon from '@/template/svg/info';
import AssistanceIcon from 'themes/spins/svg/asistenta';
import PromotionIcon from 'themes/spins/svg/promotionIcon';
import LiveIcon from 'themes/spins/svg/livertpIcon';
import LogoutIcon from 'themes/spins/svg/logouticon';
import MobileLogoSpins from 'themes/spins/svg/mobileLogoSpins';
import AventuraIconSpins from 'themes/spins/svg/aventuraIconSpins';
import SlotsIconSpins from 'themes/spins/svg/slotsIconSpins';
import SportIconSpins from 'themes/spins/svg/sportIconSpins';
import LightModeIcon from 'themes/spins/svg/lightModeIcon';
import DarkModeIcon from 'themes/spins/svg/darkModeIcon';
import MiddleLogoSpin from 'themes/spins/svg/midleLogoSpin';
import MiddleBgSpinsLogo from 'themes/spins/svg/middleBgSpinsLogo';
import BurgherIonSpins from 'themes/spins/svg/burgherIonSpins';
import BgMobileLogo from 'themes/spins/svg/bgMobileLogo';
import FacebookSpins from 'themes/spins/svg/facebookSpins';
import InstagramSpins from 'themes/spins/svg/instagramSpins';
import WheelSpins from 'themes/spins/svg/wheelSpin';
import ActiveOffers from 'themes/spins/ui/active0ffers/index';
import Button from '@/template/small_ui/button';
import CasinoLiveIcon from '@/AbsoluteComponents/themes/spins/svg/CasinoLiveIcon';
import Navs from '@/AbsoluteComponents/themes/spins/ui/header/navs';
import AccNavs from '@/AbsoluteComponents/themes/spins/ui/header/AccNavs';
import CloseIcon from '@/AbsoluteComponents/themes/spins/svg/closeicon';
import RecentPlayedIcon from '@/AbsoluteComponents/themes/spins/svg/RecentPlayedIcon';
import Tooltip from '@mui/material/Tooltip';
import BingoIcon from '@/AbsoluteComponents/themes/spins/svg/BingoIcon';
import LotoSpinIcon from '@/AbsoluteComponents/themes/spins/svg/LotoSpinIcon';
import QuestIcon from '@/AbsoluteComponents/themes/spins/svg/quest_icon';
import BonusCenter from '@/AbsoluteComponents/themes/spins/ui/bonusCenter';
import JoystickIcon from '@/AbsoluteComponents/themes/spins/svg/joystickIcon';
import useCommon from "@/AbsoluteComponents/hooks/common";
import CMS from "utils/cms";
import candyQuestLvs from "public/json/candy_quest.json";
import VerificationStatus from "@/AbsoluteComponents/interfaces/VerificationStatus";
import Game from "utils/game";
import CircularProgress from "@mui/material/CircularProgress";
import questLvs from 'public/json/spins_new_quest.json'
import {has_sport} from 'themes/spins/config/constants'
import {smartico_visible} from "@/template/config/constants";
import Offer from "@/AbsoluteComponents/interfaces/offer";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import {useTheme} from "next-themes";
import GetAppPopup from "@/AbsoluteComponents/themes/spins/small_ui/get_app_popoup";


const SpinsHeaderView: FC = () => {
    
    const router = useRouter();
    const auth = useAuth()
    const generalSettings = CMS.getDocumentGeneralSettings();
    const user = auth.getUser();
    const dispatch = useDispatch();
    const games = Game.getAll()
    const common = useCommon()
    const timer = useRef<any>(null);
    const sidebar = useRef<any>(null);
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
    let isSportPage: boolean = router.pathname.search('sport') > -1;
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const openBC:Array<Offer> = useSelector((state:any) =>  state.account.bonusCenterIsOpen);
    
    const {resolvedTheme, setTheme} = useTheme()
    const isGuest = useSelector((state: any) => state.common.isGuest)
    const [isTransition, setIsTransition] = useState<boolean>(false)
    const [scrolledDown, setScrolledDown] = useState(false);
    const [loaded, setLoaded ] = useState<boolean>(false)
    const searchActive: boolean = useSelector((state: any) => state.common.showSearchBar);
    const showSidebar: boolean = useSelector((state: any) => state.common.guestSidebar);
    const open: boolean = useSelector((state: any) => state.common.openAccSidebar);
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);
    
    
    const common_hook = useCommon()
    const data = useAuth();
    let userCandyStatus = data.getCandyQuestStatus();
    
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    const opened:Array<VerificationStatus> = useSelector((state:any) => state.account.validation_opened);
    
    let allVerified:boolean = true;
    
    verificationStatus.map(item => {
        if (!item.verified) allVerified = false;
    });
    
    const hideMenu: any = () => {
        dispatch(showGuestSideBar(false));
    }
    
    const keepOverlay: any = (e: any) => {
        e.stopPropagation();
    }
    
    const closeSearchToggle = () => {
        dispatch(showSearchBar(false));
    };
    
    const updateBalance = async () => {
        await auth.updateBalance();
        
        timer.current = setTimeout(updateBalance, 2000);
    };
    
    const showSearch:boolean = useSelector((state:any) => state.common.showSearchBar);
    const clickHandle = () => {
        
        dispatch(showSearchBar(!showSearch));
    };
    
    useEffect(() => {
        document.addEventListener("scroll", (e) => {
            if (window.scrollY > 0) {
                setScrolledDown(true);
            } else {
                setScrolledDown(false);
            }
        });
        
        document.addEventListener("resize", (e) => {
            if (window.scrollY > 0) {
                setScrolledDown(false);
            } else {
                setScrolledDown(false);
            }
        });
        
        const showScrollY = () => {
            let footer = document.getElementById('footer')
            let header = document.getElementById('header')
            
            if(footer && header) {
                let topDist = footer.getBoundingClientRect().top
                
                if(topDist < 130 && window.innerWidth >= 1000) {
                    header.style.transition = '0.2s all'
                    
                    if(header.style.transition === 'all 0.2s ease 0s') {
                        header.classList.add(styles.hide)
                    }
                    
                } else {
                    header.classList.remove(styles.hide)
                    setTimeout(() => {
                        if(header) header.style.transition = 'none'
                    }, 2000)
                }
            }
        }
        
        window.addEventListener("scroll", showScrollY)
        
        return () => {
            clearTimeout(timer.current);
            window.removeEventListener("scroll", showScrollY)
        }
        
    }, []);
    
    useEffect(() => {
        hideMenu();
        closeSearchToggle();
        closeSideBar();
    }, [router.pathname, router.query.game_slug]);
    
    useEffect(() => {
        if (sidebar.current) sidebar.current.scrollTop = 0;
    }, [showSidebar]);
    
    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
        
        setLoaded(true)
    }, [])
    
    useEffect(() => {
        dispatch(openAccountSidebar(false))
    }, [auth.isLoggedIn()])
    
    useEffect(() => {
        if(open) {
            common_hook.hideZendesk(true)
        } else {
            common_hook.hideZendesk(false)
        }
    }, [open]);
    
    const ShowSideBar = () => {
        dispatch(openAccountSidebar(!open))
        
        if (open) {
            dispatch(showTermsSideBar(false))
        }
    }
    
    const closeSideBar = () => {
        if (open) {
            dispatch(openAccountSidebar(false))
        }
    }
    
    const navigateProfile = (not_verified:boolean) => {
        if(not_verified) {
            router.push('/account/account-validation')
            ShowSideBar()
        } else {
            router.push('/account/profile')
            ShowSideBar()
        }
    }
    
    const handleSwitchColorTheme = () => {
        setTheme(resolvedTheme === 'dark' ? 'light' : 'dark');
        setTimeout(() => {
            // closeSideBar();
        }, 1000)
    }
    
    let currentLevel = candyQuestLvs.find((lv:any) => {
        return lv.Nivel === userCandyStatus.level
    })
    
    const getPercentage = (): number => {
        let questPercentage: number = 0;
        if (currentLevel && currentLevel.Nivel !== 40) {
            let minPoints: number = currentLevel.Puncte;
            
            let maxPoints: any = candyQuestLvs[userCandyStatus.level].Puncte
            
            let currentLvTotalPoints: number = maxPoints - minPoints;
            
            let currentLvUserPoints: number = userCandyStatus.points - minPoints;
            let percent: number = currentLvTotalPoints / 100;
            questPercentage = parseFloat((currentLvUserPoints / percent).toFixed(2))
            
        } else if (currentLevel && currentLevel.Nivel === 40) {
            questPercentage = 100
        }
        
        return questPercentage
    }
    
    const primary_links = [
        {
            name: strings['sots'],
            path: "/slots",
            icon: <SlotsIconSpins/>,
            visible: true
        },
        {
            name: strings['cazino_live'],
            path: "/casino-live",
            icon: <CasinoLiveIcon/>,
            visible: true
        },
        {
            name: strings['sports'],
            path: "/sport",
            icon: <SportIconSpins/>,
            visible: has_sport
        },
    ]
    
    const burger_primary_links = [
        ... primary_links,
        {
            name: strings['loto'],
            path: "/loto",
            icon: <LotoSpinIcon/>,
            visible: true
        },
        {
            name: strings['bingo'],
            path: "/bingo",
            icon: <BingoIcon/>,
            visible: true
        },
    ]
    
    const left_buttons = [
        {
            className: clsx(
                styles.searchBlock,
                styles.headerSearch_block,
                "theme_searchInput_header",
                "theme_text_mainColor",
                styles.blink,
                router.asPath === "/"+CMS.getCurrentQuest().url_id && styles.active
            ),
            
            onClick: () => {router.push("/"+CMS.getCurrentQuest().url_id)},
            icon: <QuestIcon/>,
            visible: !CMS.getCurrentQuest().hardcoded && !isSportPage,
            ariaLabel: "quest"
        },
        // {
        //     className: clsx(
        //         styles.searchBlock,
        //         styles.headerSearch_block,
        //         "theme_searchInput_header",
        //         "theme_text_mainColor",
        //     ),
        //
        //     onClick: () => {dispatch(openWheelModal(true))},
        //     icon: <WheelSpins/>,
        //     visible: !auth.isLoggedIn(),
        //     ariaLabel: "wheel"
        // },
        {
            className: clsx(
                styles.searchBlock,
                styles.headerSearch_block,
                "theme_searchInput_header",
                "theme_text_mainColor",
                router.asPath === "/account/available-offers" && styles.active
            ),
            
            onClick: () => {
                router.push("/account/available-offers")
            },
            icon: <ActiveOffers/>,
            visible: auth.isLoggedIn() && !smartico_visible,
            ariaLabel: "offers"
        },
        
        {
            className: clsx(
                styles.searchBlock1,
                styles.headerSearch_block,
                "theme_searchInput_header",
                "theme_text_mainColor",
                // styles.blink,
                // router.asPath === "/account/available-offers" && styles.active
            ),
            
            icon: <BonusCenter/>,
            visible: smartico_visible && auth.isLoggedIn(),
            ariaLabel: "bonus center"
        },
        
        {
            className: clsx(
                styles.searchBlock,
                styles.headerSearch_block,
                "theme_searchInput_header",
                "theme_text_mainColor",
                searchActive && styles.active
            ),
            
            onClick: clickHandle,
            icon: <SearchIcon/>,
            visible: true,
            ariaLabel: "search"
        },
    
    ]
    
    return (
        <>
            
            <div id={'header'} className={clsx(
                styles.headerContainerWrapper,
                'theme_front_elements_bg',
                'header_bordered',
                'header-scroll',
                scrolledDown && 'fixed-scroll',
                searchActive && 'active-search',
            )}>
                <GetAppPopup />
                <div className={styles.wrapperNavBar}>
                    <div className={clsx(styles.headerContainer,)}>
                        
                        <div className={(styles.headerContainer__inner)}>
                            
                            <Link  aria-label={"home"} href={'/'} className={'mediumBgWrapper'}>
                                <span className={clsx('mediumBgHeader',)}>
                                    <span className={'theme_header_logo__mini'}>
                                        <Image data-hide-on-theme="light" src={"/spins/images/logo_bg_dark.webp"}
                                               alt={"logo_bg"} width={300} height={100}/>
                                        <Image
                                            data-hide-on-theme="dark"
                                            src={"/spins/images/logo_bg_light.webp"}
                                            alt={"logo_bg"}
                                            width={300}
                                            height={100}
                                        />
                                        {/*<MediumBgHeader />*/}
                                    </span>
                                </span>
                                
                                <span className={clsx('mediumBgLogo', 'theme_svgLogo')}>
                                    <Image src={"/spins/images/Logo.webp"} alt={"logo-mid"} width={121} height={39}/>
                                </span>
                            </Link>
                            
                            
                            <div className={styles.flex_left}>
                                <div
                                    onClick={ShowSideBar}
                                    className={clsx(styles.columnFields, 'menuHeader', styles.resp_burger)}
                                >
                                    <span
                                        className={clsx(styles.columnFields__icon, 'iconBurgherDefault', "theme_white_dark_text")}>
                                        <BurgherIonSpins/>
                                    </span>
                                </div>
                                
                                <Navs className={styles.nav_desktop} primary_links={primary_links}/>
                            
                            </div>
                            
                            <Link aria-label={"home"} href={'/'} className={styles.middleLogoWrapper}>
                                <span className={styles.middleLogoWrapper__logo}>
                                   <MiddleLogoSpin/>
                                </span>
                                
                                <span className={clsx(styles.middleLogoWrapper__bgLogo, 'theme_header_logo')}>
                                    <MiddleBgSpinsLogo/>
                                </span>
                            </Link>
                            
                            <Link aria-label={"home"} className={clsx(styles.headerContainer__logo)} href={'/'}
                                  onClick={closeSearchToggle}>
                                <img src={'/spins/images/Logo.webp'} width={534} height={177} alt={"logo"}/>
                            
                            </Link>
                            
                            
                            <span className={clsx(styles.wrapperLogoBg, 'theme_header_logo')}>
                                <img data-hide-on-theme='light' src={'/spins/images/BretonLogoBkg.svg'} width={326}
                                     height={94} alt={"logoBg"}/>
                                <img data-hide-on-theme='dark' src={'/spins/images/BretonLogoBgLight.svg'} width={326}
                                     height={94} alt={"logoBg"}/>
                            </span>
                            
                            <div className={styles.bgLogoMobileDarkMode}>
                                <span className={'theme_header_logo__mini'}>
                                    <BgMobileLogo/>
                                </span>
                            </div>
                            
                            <Link  aria-label={"home"} href={'/'}
                                   className={clsx(styles.logoMobileDarkMode, 'logoMobileDarkMode')}>
                                <span className={'theme_svgLogo'}><MobileLogoSpins/></span>
                            </Link>
                            
                            <div className={styles.adventureCandyCrash}>
                                
                                {questLvs.length > 0 ? <div
                                    onClick={() => {
                                        // QUEST LINK
                                        router.push('/')
                                    }}
                                    className={clsx(styles.adventureCandyCrash__wrapper, 'theme_box_bg_shaded2')}
                                >
                                   <span
                                       className={clsx('theme__svg', 'theme_quest_icon', !userCandyStatus.optIn && 'not_participating')}>
                                       <AventuraIconSpins/>
                                   </span>
                                    
                                    <div style={{textAlign: 'center', marginLeft: '8px'}}>
                                        
                                        <div className={'theme_textColor_secondary'}>Candy Cash</div>
                                        
                                        <span className={styles.score}>
                                            {userCandyStatus?.optIn ? (
                                                <>
                                                    <span className={styles.yellow}>
                                                    <span
                                                        className={'theme_textColor_secondary'}>LVL {userCandyStatus.level}</span>
                                                    </span>
                                                    
                                                    <span
                                                        className={'theme_textColor_secondary'}> /{candyQuestLvs.length}</span>
                                                </>
                                            ) : (
                                                <span className={'theme_textColor_secondary'}> Participă </span>
                                            )}
                                            
                                            <span className={'scoreBlockSidebar'} style={{
                                                height: ' 100%',
                                                background: '#FFB95066',
                                                borderRadius: '30px',
                                                width: `${userCandyStatus?.optIn ? getPercentage() : '100'}%`
                                            }}/>
                                        </span>
                                    </div>
                                </div> : null}
                                
                                <div style={{display: "flex", alignItems: "center"}}
                                     className={clsx('theme__svg', 'header_campaign')}>
                                    
                                    {/*<Link href={'/promotii'} className={clsx('theme__svg', 'theme_text_mainColor')}>*/}
                                    {/*    <CampagneIconSpins/>*/}
                                    {/*</Link>*/}
                                    
                                    <Navs primary_links={primary_links} className={styles.nav_mobile}/>
                                    
                                    {router.pathname.slice(1, 6) !== 'sport' && (
                                        <span style={{cursor: 'pointer'}} onClick={clickHandle}>
                                            <span onClick={() => {
                                            }} style={{marginLeft: '15px'}}><SearchIcon/></span>
                                        </span>
                                    )}
                                </div>
                            </div>
                            
                            <div
                                className={clsx(
                                    'rightSideBtn',
                                    searchActive && 'search-active',
                                    styles.headerContainer__buttons
                                )}
                            >
                                
                                {router.pathname.slice(1, 6) !== 'sport' && (
                                    
                                    <>
                                        {left_buttons.map((item: any, i: number) => {
                                            return item.visible && (
                                                <button
                                                    className={item.className}
                                                    onClick={item.onClick}
                                                    key={"left_button" + i}
                                                    aria-label={item.ariaLabel}
                                                >
                                                    {item.icon}
                                                </button>
                                            )
                                        })}
                                    
                                    </>
                                
                                )}
                                
                                {auth.isUpdating() && (
                                    <div className={styles.authPlaceholder}>
                                        <CircularProgress size={18}/>
                                    </div>
                                )}
                                
                                {auth.isLoggedIn() && (
                                    <>
                                        <div className={clsx(styles.sideBar__block)}>
                                            <Balance/>
                                        </div>
                                        
                                        <div className={'userDropDown'}>
                                            <UserDropDown/>
                                        </div>
                                    </>
                                
                                )}
                                
                                {/*ADDED START*/}
                                
                                {(!auth.isLoggedIn() && !auth.isUpdating() && isGuest) &&
                                    <div className={clsx(styles.sidebar__btnLog, styles.header__btnLog)}>
                                        
                                        <Button
                                            onClick={() => {
                                                dispatch(openLoginModal(true))
                                                const root = document.getElementsByTagName('html')[0];
                                                root.setAttribute('style', 'overflow-y: hidden');
                                            }}
                                            outlined
                                            text={strings['conectare']}
                                        />
                                        
                                        <Button
                                            type={'button'}
                                            onClick={() => {
                                                dispatch(openSignupModal(true))
                                                const root = document.getElementsByTagName('html')[0];
                                                root.setAttribute('style', 'overflow-y: hidden');
                                            }}
                                            text={strings['cont_nou']}
                                        
                                        />
                                    </div>
                                }
                            </div>
                        
                        </div>
                        
                        <SearchBar/>
                    
                    </div>
                </div>
            </div>
            
            {/*THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  */}
            <div
                className={clsx(styles.overlay,
                    open && styles.active
                )}
                onClick={ShowSideBar}
            >
                
                <div
                    style={{transition: `${isTransition && 'all 0.3s ease'}`}}
                    className={clsx(
                        styles.sidebar,
                        'theme_front_elements_bg',
                        open && styles.active)
                    }
                    onClick={keepOverlay}
                    ref={sidebar}
                >
                    
                    <div className={clsx(styles.sideBar_wrapper, styles.sidebarBlock)}>
                        
                        <div className={styles.sidebarBlock__controllers}>
                            
                            <div style={{padding: '0 16px'}}>
                                
                                {/*<div className={'btnSidebar'}>*/}
                                {/*    <span*/}
                                {/*        className={clsx('btnSidebar__title', 'theme_textColor_secondary')}>Menu </span>*/}
                                {/*    <button onClick={() => {*/}
                                {/*        dispatch(openAccountSidebar(false))*/}
                                {/*    }} type={'button'} className={clsx(styles.sidebar__close)}>*/}
                                {/*        <span className={'theme__svg'}><Close/></span>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                
                                <div className={styles.sidebar__logo}>
                                    
                                    <button aria-label={"clase"}
                                            className={clsx("theme_box_bg_unshaded6", "theme_white_dark_text")}
                                            onClick={ShowSideBar}>
                                        <CloseIcon/>
                                    </button>
                                    
                                    <Image
                                        className={'first-logo'}
                                        src={"/spins/images/Logo.webp"}
                                        alt={'logo'}
                                        width={242}
                                        height={78}
                                        onClick={() => {
                                            router.push("/")
                                        }}
                                    />
                                    
                                    <div style={{width: "56px"}}></div>
                                </div>
                                
                                <div>
                                    <div className={clsx(styles.sidebar__btn, 'theme_searchInput_header')}>
                                        {!auth.isLoggedIn() && (
                                            <div className={styles.sidebar__btnWrap}>
                                                
                                                <Button
                                                    onClick={() => {
                                                        dispatch(openLoginModal(true))
                                                        const root = document.getElementsByTagName('html')[0];
                                                        root.setAttribute('style', 'overflow-y: hidden');
                                                    }}
                                                    outlined
                                                    text={strings['conectare']}
                                                />
                                                
                                                <Button
                                                    type={'button'}
                                                    onClick={() => {
                                                        dispatch(openSignupModal(true))
                                                        const root = document.getElementsByTagName('html')[0];
                                                        root.setAttribute('style', 'overflow-y: hidden');
                                                    }}
                                                    text={strings['cont_nou']}
                                                />
                                            
                                            </div>
                                        )}
                                        
                                        {auth.isLoggedIn() && (
                                            <>
                                                
                                                <div className={styles.sidebar__btnWrap}>
                                                    
                                                    <Button
                                                        style={{padding: '0 32px'}}
                                                        onClick={() => auth.openWithdrawPopup()}
                                                        text={strings['retragere']}
                                                        outlined
                                                    />
                                                    
                                                    <Button
                                                        style={{padding: '0 32px'}}
                                                        type={'button'}
                                                        onClick={() => dispatch(openDeposit(true))}
                                                        text={strings['depunere']}
                                                    />
                                                
                                                </div>
                                                
                                                <AccNavs/>
                                                
                                                <div className={styles.sidebar__userBlock}>
                                                    
                                                    <Tooltip title={((!allVerified || opened) && !isMobile) ?"Contul nu este verificat" : ""} placement={'bottom-end'}>
                                                        <div
                                                            onClick={() => {
                                                                SmarticoClass.changeAvatar()
                                                            }}
                                                            style={{cursor: 'pointer'}}
                                                            className={clsx(styles.sidebar__user, (!allVerified || opened) && 'notVerified', "auth", "inSidebar")}
                                                        >
                                                            {(typeof smartico_props === "object" && Object.keys(smartico_props).length) ? (
                                                                <img src={smartico_props?.avatar_id} alt={""} width={100} height={100} />
                                                            ) : (
                                                                <div
                                                                    className={clsx(styles.sidebar__userName, "theme_text_mainColor")}
                                                                >
                                                                    {user.firstName[0]}
                                                                    {user.lastName[0]}
                                                                </div>
                                                            )}
                                                        
                                                        </div>
                                                    </Tooltip>
                                                    
                                                    <div className={styles.sidebar__userData}>
                                                        <div
                                                            onClick={() => {
                                                                navigateProfile(Boolean(!allVerified || opened));
                                                            }}
                                                            style={{cursor: 'pointer'}}
                                                            className={clsx(styles.sidebar__userFullName, 'theme_textColor_secondary')}
                                                        >
                                                            {user.firstName} {user.lastName}
                                                        </div>
                                                        
                                                        <div className={styles.sidebar__balanceBlock}>
                                                            <div
                                                                className={clsx(styles.sidebar__balance, 'theme__headerContainer__list')}>
                                                                {strings['balanta']}
                                                            </div>
                                                            
                                                            <div
                                                                className={clsx(styles.sidebar__balanceItem, 'theme_textColor_secondary')}>
                                                                {auth.getUserBalance().toFixed(2)}
                                                                <span
                                                                    className={clsx(styles.sidebar__currence, 'theme__headerContainer__list')}>
                                                          {auth.getCurrency()}
                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                
                                <div className={clsx(styles.img_buttons)}>
                                    <div
                                        onClick={() => {
                                            if(auth.isLoggedIn()) {
                                                SmarticoClass.openDashboard()
                                            } else {
                                                dispatch(openLoginModal(true))
                                            }
                                        }}
                                        className={clsx(styles.playhub_section)}
                                    >
                                        <JoystickIcon />
                                        
                                        <span>
                                            {strings["play_hub"]}
                                        </span>
                                    </div>
                                    
                                    <div
                                        onClick={() => {
                                            router.push("/promotii")
                                        }}
                                        className={styles.prom_section}
                                    >
                                        <PromotionIcon/>
                                        
                                        <span>
                                            {strings["promotions"]}
                                        </span>
                                    </div>
                                </div>
                                
                                <div className={styles.primary_links}>
                                    {burger_primary_links.map((item: any, i: number) => {
                                        
                                        let isPage = router.asPath.search(item.path) > -1
                                        
                                        return item.visible ? (
                                            <button
                                                key={"primary_link" + i}
                                                onClick={() => {
                                                    router.push(item.path)
                                                }}
                                                className={clsx(isPage && styles.inPage)}
                                            >
                                                {item.icon}
                                                {item.name}
                                            </button>
                                        ) : null
                                    })}
                                
                                </div>
                            
                            </div>
                            
                            <div
                                style={{padding: '0 8px', marginTop: '16px'}}
                                className={clsx(
                                    styles.sidebar__links,
                                    'theme_sidebar__links',
                                )}
                            >
                                
                                {/*<li*/}
                                {/*    className={clsx(*/}
                                {/*        'theme__svg__sidebar',*/}
                                {/*        "theme_white_dark_text",*/}
                                {/*        styles.quest_link,*/}
                                {/*    )}*/}
                                {/*>*/}
                                {/*    <div*/}
                                {/*        style={{*/}
                                {/*            width: "100%"*/}
                                {/*        }}*/}
                                {/*        className={clsx(styles.sidebar__linkSearch, styles.headerContainer__list, 'theme__headerContainer__list', 'searchLineSidebar')}*/}
                                {/*        onClick={() => {*/}
                                {/*            if(auth.isLoggedIn()) {*/}
                                {/*                SmarticoClass.openDashboard()*/}
                                {/*            } else {*/}
                                {/*                dispatch(openLoginModal(true))*/}
                                {/*            }*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        */}
                                {/*        <span className={clsx('theme__svg')}>*/}
                                {/*            <JoystickIcon/>*/}
                                {/*        </span>*/}
                                {/*        */}
                                {/*        {strings['play_hub']}*/}
                                {/*    </div>*/}
                                {/*</li>*/}
                                
                                {!CMS.getCurrentQuest().hardcoded && (
                                    <div
                                        className={clsx(
                                            styles.quest_link,
                                            "theme_white_dark_text"
                                        )}
                                    >
                                        <Link   href={'/' + CMS.getCurrentQuest().url_id}>
    
                                            <span className={clsx('theme__svg')}>
                                                <QuestIcon/>
                                            </span>
                                            
                                            Aventura Insula Comorii
                                        
                                        </Link>
                                    </div>
                                )}
                                
                                {/*{(smartico_visible && auth.isLoggedIn()) && (*/}
                                {/*    <div*/}
                                {/*        className={clsx(*/}
                                {/*            styles.quest_link,*/}
                                {/*            "theme_white_dark_text"*/}
                                {/*        )}*/}
                                {/*    >*/}
                                {/*        <button onClick={() => {SmarticoClass.openDashboard()}} className={"theme_white_dark_text"} >*/}
                                
                                {/*            <span className={clsx('theme__svg')}>*/}
                                {/*                /!*<QuestIcon/>*!/*/}
                                {/*            </span>*/}
                                {/*            */}
                                {/*            Smartico*/}
                                {/*        */}
                                {/*        </button>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                
                                <li
                                    className={clsx(
                                        'theme__svg__sidebar',
                                        "theme_white_dark_text"
                                    )}
                                >
                                    <div
                                        className={clsx(styles.sidebar__linkSearch, styles.headerContainer__list, 'theme__headerContainer__list', 'searchLineSidebar')}
                                        onClick={() => {
                                            dispatch(showSearchBar(true));
                                            hideMenu();
                                            dispatch(openAccountSidebar(false))
                                        }}
                                    >
                                        
                                        <span className={clsx('theme__svg')}>
                                            <SearchIcon/>
                                        </span>
                                        
                                        {strings['search']}
                                    </div>
                                </li>
                                
                                {auth.isLoggedIn() &&
                                    
                                    <>
                                        <li
                                            className={clsx(
                                                styles.headerContainer__list,
                                                'theme__headerContainer__list',
                                                'theme__svg__sidebar',
                                                'theme_sidebar__links',
                                                router.asPath === '/favorites' && 'selected'
                                            )}
                                        
                                        >
                                            <Link  href={'/favorites'}>
    
                                                <span className={clsx('theme__svg')}>
                                                    <FavoriteFalseIcon/>
                                                </span>
                                                
                                                {strings['favorite']}
                                            
                                            </Link>
                                        </li>
                                        
                                        <li
                                            className={clsx(
                                                styles.headerContainer__list,
                                                'theme__headerContainer__list',
                                                'theme__svg__sidebar',
                                                'theme_sidebar__links',
                                                router.asPath === '/last-played' && 'selected'
                                            )}
                                        >
                                            <Link   href={'/last-played'}>
    
                                            <span className={clsx('theme__svg',)}>
                                                <RecentPlayedIcon/>
                                            </span>
                                                
                                                {strings['last-played']}
                                            
                                            </Link>
                                        </li>
                                        
                                        <li
                                            className={clsx(
                                                styles.headerContainer__list,
                                                'theme__headerContainer__list',
                                                'theme__svg__sidebar',
                                                'theme_sidebar__links',
                                                router.asPath === '/account/available-offers' && 'selected'
                                            )}>
                                            <Link  href={'/account/available-offers'}>
                                            <span className={clsx('theme__svg')}>
                                                <ActiveOffers isSideBar/>
                                            </span>
                                                {strings['oferte_active']}
                                            </Link>
                                        </li>
                                    </>
                                }
                                
                                <li
                                    className={clsx(
                                        styles.headerContainer__list,
                                        'theme__headerContainer__list',
                                        'theme__svg__sidebar',
                                        'theme_sidebar__links',
                                        router.asPath === '/live-rtp' && 'selected'
                                    )}
                                >
                                    <Link  href={'/live-rtp'}>
                                        <span className={clsx('theme__svg')}>
                                            <LiveIcon/>
                                        </span>
                                        {strings['life_rtp']}
                                    </Link>
                                </li>
                                
                                <li
                                    className={clsx(
                                        styles.headerContainer__list,
                                        'theme__headerContainer__list',
                                        'theme__svg__sidebar',
                                        'theme_sidebar__links',
                                        router.asPath === '/contact' && 'selected'
                                    )}
                                >
                                    <Link  href={'/contact'}>
                                        <span className={clsx('theme__svg')}>
                                            <AssistanceIcon/>
                                        </span>
                                        {strings['asistenta']}
                                    </Link>
                                </li>
                                
                                <li
                                    className={clsx(
                                        styles.headerContainer__list,
                                        'theme__headerContainer__list',
                                        'theme__svg__sidebar',
                                        'theme_sidebar__links',
                                        router.asPath === '/privacy-policy' && 'selected'
                                    )}
                                    style={{marginBottom: '5px'}}
                                >
                                    <Link href={'/privacy-policy'}>
                                        <span className={clsx('theme__svg')}>
                                            <InfoIcon/>
                                        </span>
                                        {strings['informatii']}
                                    </Link>
                                </li>
                            </div>
                        </div>
                        
                        <div style={{marginTop: "70px"}} className={clsx('modeBlock_wrapper')}>
                            <div className={clsx(styles.sidebar__socialBlock, 'modeBlock_wrapper')}>
                                
                                {auth.isLoggedIn() &&
                                    <div className={clsx(
                                        styles.sidebar__links,
                                        'theme__svg__sidebar',
                                        'theme__headerContainer__list',
                                        styles.logout_btn
                                    )}>
                                        <button
                                            onClick={() => {
                                                auth.signOut();
                                                hideMenu();
                                                dispatch(openAccountSidebar(false))
                                            }}
                                            className={clsx(styles.dropDown__logOut, 'theme_sidebar__links', 'logout')}
                                            style={{paddingLeft: 16}}
                                        >
                                            <div style={{marginRight: 12}}>
                                                <LogoutIcon/>
                                            </div>
                                            
                                            <span
                                                className={clsx(styles.userLogOut, 'themeLogOut_text', 'theme__headerContainer__list', "theme_white_dark_text")}>{strings['iesire_din_cont']}</span>
                                        </button>
                                    </div>
                                }
                                
                                <div className={styles.mobileButtonSocial}>
                                    {generalSettings.instagram.length > 0 &&
                                        <Link
                                            aria-label={"insta"}
                                            className={clsx(styles.sidebar__socialLink, styles.sidebar__facebook, 'btn__bg__themeSocial')}
                                            target="_blank" href={generalSettings.instagram}>
                                            <span style={{display: 'flex'}}
                                                  className={'theme__svg'}><InstagramSpins/></span>
                                        </Link>
                                    }
                                    
                                    {generalSettings.facebook.length > 0 &&
                                        <Link
                                            aria-label={"fb"}
                                            className={clsx(styles.sidebar__socialLink, styles.sidebar__facebook, 'btn__bg__themeSocial')}
                                            target="_blank" href={generalSettings.facebook}>
                                            <span style={{display: 'flex'}}
                                                  className={'theme__svg'}><FacebookSpins/></span>
                                        </Link>
                                    }
                                </div>
                                
                                <div className={clsx(styles.bottomBlockSidebar)}>
                                    <div className={clsx(styles.modeBlock, 'theme_searchInput_header')}>
                                        <button
                                            onClick={handleSwitchColorTheme}
                                            className={clsx(styles.modeBlock__button, 'theme__svg',
                                                styles.firstbtnMode,
                                                'theme-btn-light',
                                                (loaded && resolvedTheme === 'dark') && 'iconMode__Color',
                                                (loaded && resolvedTheme === 'light') && 'iconLightMode',
                                                'iconMode__Color'
                                            )}>
                                            <LightModeIcon/>
                                            <span>Dark</span>
                                        </button>
                                        
                                        <button
                                            onClick={handleSwitchColorTheme}
                                            className={clsx(styles.modeBlock__button, 'theme__svg', styles.secondbtnMode,
                                                'theme-btn-dark',
                                                (loaded && resolvedTheme === 'light') ? 'iconMode__ColorLight' : '',
                                            )}>
                                            <DarkModeIcon/>
                                            <span>Light</span>
                                        </button>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/*THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  */}
        
        </>
    )
}

export default SpinsHeaderView;