import {FC} from 'react';
import CMS from "utils/cms";
import Link from 'next/link';
import clsx from 'clsx';
import styles from 'themes/spins/styles/small_pages.module.scss'
import Head from 'next/head';
import strings from "@/template/config/strings"
import Image from "next/image"

interface Props {
    json:any;
    api_id:string;
}

const AllProviders:FC<Props>  = ({api_id}) => {

    let data = CMS.jsonAllProvidersPages()


    return(
        <div className={clsx('page-container', styles.allProviders)}>

            <Head>
                <title>{strings['all_providers_page_title']}</title>
            </Head>

            <h1 className={clsx(styles.allProviders__title, 'theme_white_dark_text')}>{data.title}</h1>

            <div  className={clsx(styles.allProviders__provider_block)}>

                {data.providers.map((item:any, i:number) => {
                    return (
                        <div key={'providers'+ i} className={clsx(styles.allProviders__provider_item, 'theme__sliderButtonContainer', 'providers')}>
                            <Link   className={clsx('')}  style={{display:'inline-block'}} href={'/'+ item.api_id}>

                                {!Boolean(item.image_dark?.length) && (
                                    <img
                                        width={150}
                                        height={100}
                                        src={item['thumbnail_src']}
                                        alt={item['name']}
                                    />
                                )}

                                {Boolean(item.image_dark?.length) && (
                                    <>
                                        <img
                                            width={150}
                                            height={100}
                                            data-hide-on-theme="dark"
                                            src={CMS.getPublicFilePath(item.image_dark[0].name, 'slots_test', item.image_dark[0].path)}
                                            alt={item['name']}
                                        />
                                        
                                        <img
                                            width={150}
                                            height={100}
                                            data-hide-on-theme="light"
                                            src={item['thumbnail_src']}
                                            alt={item['name']}
                                        />
                                    </>
                                )}
                            </Link>
                        </div>
                    )
                })}

            </div>
        </div>
    )
};

export default AllProviders;