import clsx from 'clsx';

function AventuraIconSpins() {
    return(
        <svg className={clsx('quest_icon_mox_resize', 'svg-icon')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            {/*<mask id="mask0_1001_9021" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">*/}
            {/*    <rect width="24" height="24" fill="#D9D9D9"/>*/}
            {/*</mask>*/}
            {/*<g mask="url(#mask0_1001_9021)">*/}
                <path fillRule="evenodd" clipRule="evenodd" d="M7.71 2.25H18.63C17.7685 2.25 17.07 2.9776 17.07 3.875V18.9062C17.07 19.5793 16.5463 20.125 15.9 20.125C15.2537 20.125 14.73 19.5793 14.73 18.9062V18.7031C14.73 17.9178 14.1187 17.2812 13.365 17.2812H6.15V3.875C6.15 2.9776 6.8485 2.25 7.71 2.25ZM10.83 4.6875C10.3992 4.6875 10.05 5.0513 10.05 5.5C10.05 5.9487 10.3992 6.3125 10.83 6.3125H13.95C14.3808 6.3125 14.73 5.9487 14.73 5.5C14.73 5.0513 14.3808 4.6875 13.95 4.6875H10.83ZM9.27 7.9375C8.83925 7.9375 8.49 8.3013 8.49 8.75C8.49 9.1987 8.83925 9.5625 9.27 9.5625H13.95C14.3808 9.5625 14.73 9.1987 14.73 8.75C14.73 8.3013 14.3808 7.9375 13.95 7.9375H9.27ZM9.27 11.1875C8.83925 11.1875 8.49 11.5513 8.49 12C8.49 12.4487 8.83925 12.8125 9.27 12.8125H13.95C14.3808 12.8125 14.73 12.4487 14.73 12C14.73 11.5513 14.3808 11.1875 13.95 11.1875H9.27Z" fill="#929396"/>
                <path d="M18.63 4.6875C18.63 3.7901 19.3285 3.0625 20.19 3.0625C21.0515 3.0625 21.75 3.7901 21.75 4.6875V12H18.63V4.6875Z" fill="#929396"/>
                <path d="M16.29 21.75C14.567 21.75 13.17 20.295 13.17 18.5H2.25C2.25 20.295 3.64699 21.75 5.37 21.75H16.29Z" fill="#929396"/>
            {/*</g>*/}
        </svg>
    )
}

export default AventuraIconSpins