import Modal from 'react-modal';
import clsx from 'clsx';
import {useState, useEffect, useRef} from 'react';
import Link from 'next/link';
import useAuth from "hooks/auth";
import {getAuthToken, getRealityCheckEndTime, setRealityCheckEndTime} from "utils/cookies";
import {getRealityCheck, resetRealityCheck} from "services/account";
import Button from "@/template/small_ui/button";
import styles from '@/template/styles/modals.module.scss';
import ModalLayout from "@/template/ui/modal_layout";


const RealityCheck = () => {
    const [loading, setLoading] = useState(false);
    const [loadingOff, setLoadingOff] = useState(false);
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState({
        bets: 0,
        wins: 0,
        lost: 0,
        interval: 0,
        spendTime: 0,
        currency: 'RON',
    });

    const auth = useAuth();
    const timer = useRef<any>(null);

    const resetHandle = async () => {
        setLoading(true);
        const data = await resetRealityCheck();
        if (!data.error && Number(data.response['endTime'])) {
            setRealityCheckEndTime(Number(data.response['endTime']));
            timer.current = setTimeout(runChecker, 30000);
        }

        setLoading(false);
        setOpen(false);
    };

    const runChecker = async ():Promise<void> => {
        const endTime:number = getRealityCheckEndTime();
        const token:string = getAuthToken();
        const date = new Date();

        if (endTime > 0 && !open && date.getTime() >= endTime  && token.length) {
            const data = await getRealityCheck();
            
            if (data.response?.interval) {
                setInfo((prevInfo:any) => ({
                    ...prevInfo,
                    bets: data.response.bets,
                    wins: data.response.wins,
                    lost: data.response.lost,
                    spendTime: data.response.spendTime,
                    interval: data.response.interval,
                }));

                setOpen(true);
            }

        } else {
            timer.current = setTimeout(runChecker, 30000);
        }

    };

    useEffect(() => {
        runChecker();
    }, []);
    
    
    return (

        <Modal

            ariaHideApp={false}
            className={clsx('ReactModal__SM', styles.realityCheck, 'theme_box_bg_shaded3')}
            isOpen={open}
            onRequestClose={() => {}}
            contentLabel="Example Modal"
        >
            <ModalLayout title={'Informare'}>

                <div className={clsx('ReactModal__Inner')}>

                    {/*<div className="ReactModal__Inner__Header">*/}
                    {/*    <h2 className="ReactModal__Title">*/}
                    {/*        Informare*/}
                    {/*    </h2>*/}
                    {/*</div>*/}

                    <div className="ReactModal__Inner__Content theme_text_mainColor">
                        <div>
                            <p>Ești conectat de {info.spendTime} minut(e) pe site.</p>

                            <p>
                                Pentru detalii suplimentare ale tranzacțiilor apasă
                                <Link   className={'theme_white_dark_text'} style={{marginLeft: '5px', fontWeight: '700'}} href={'/account/transactions/game'}>
                                    aici
                                </Link>
                            </p>

                            <div className={clsx(styles.realityCheck__table, 'theme_white_dark_text')}>
                                <div className={styles.realityCheck__table_item}>
                                    <span style={{textAlign: 'left', textTransform: 'none'}}>Ai pariat</span>
                                    <span>{info.bets.toFixed(2)} {info.currency}</span>
                                </div>

                                <div className={styles.realityCheck__table_item}>
                                    <span style={{textAlign: 'left', textTransform: 'none'}}>Ai câștigat</span>
                                    <span>{info.wins.toFixed(2)} {info.currency}</span>
                                </div>

                                <div className={styles.realityCheck__table_item}>
                                    <span style={{textAlign: 'left', textTransform: 'none'}}>Ai pierdut</span>
                                    <span>{info.lost.toFixed(2)} {info.currency}</span>
                                </div>

                            </div>

                            <div className={styles.reality_check_control}>

                                <Button
                                    text={'Ieșire din cont'}
                                    style={{marginRight: 5}}
                                    outlined
                                    loading={loadingOff}
                                    disabled={loading}
                                    type={'button'}
                                    onClick={async () => {
                                        setLoadingOff(true);
                                        await auth.signOut();
                                        setOpen(false);
                                        setLoadingOff(false);
                                    }}
                                />

                                <Button
                                    text={'Continuare'}
                                    disabled={loadingOff}
                                    loading={loading}
                                    type={'button'}
                                    onClick={() => resetHandle()}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </ModalLayout>

        </Modal>
    )
}

export default RealityCheck;